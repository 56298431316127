import styled from "styled-components";

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Button = styled.button`
  align-items: center;
  background-color: #000;
  border-radius: 9999px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 196px;
  will-change: transform;
  color: white;
  cursor: pointer;
  p {
    font-size: 15px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 500;
  }
  @media (max-width: 480px) {
    background-color: transparent;
    color: black;
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #000000;
  width: max-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Option = styled.div`
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  background: #2e2e2e;
  height: 48px;
  padding: 13px 16px 13px 12px;
  gap: 12px;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.6;
  }
`;

export const GreenLight = styled.div`
  width: 6px;
  height: 6px;

  background: #bfe000;
  box-shadow: 0px 0px 30.24px #bfe000, 0px 0px 17.28px #bfe000,
    0px 0px 10.08px #bfe000, 0px 0px 5.04px #bfe000, 0px 0px 1.44px #bfe000,
    0px 0px 0.72px #bfe000;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 50%;
`;
