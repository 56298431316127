import React, { useState } from "react";
import Spline from "@splinetool/react-spline";
import {
  CardWrapper,
  ImageWrapper,
  OffsetButtonWrapper,
  CardContentWrapper,
  Description,
} from "./styled";
import { NFTCardProps } from "components/types";
import { OwnerInfo } from "components";
import { Hexagon } from "icons";

const NFTCard: React.FC<NFTCardProps> = ({
  primaryImg,
  secondaryImg,
  title,
  description,
  owner,
}) => {
  const [offset, setOffset] = useState(false);
  return (
    <CardWrapper>
      <ImageWrapper>
        {offset ? (
          // <iframe
          //   title="frame"
          //   src={secondaryImg}
          //   style={{ width: "100%", height: "100%", border: "none" }}
          //   loading="lazy"
          //   sandbox="allow-same-origin allow-scripts allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          // ></iframe>
          <Spline scene={secondaryImg} />
        ) : (
          <img src={primaryImg} alt="nft" />
        )}
        <OffsetButtonWrapper onClick={() => setOffset(!offset)}>
          <Hexagon />
          {offset ? <p>ART VIEW</p> : <p>VR VIEW</p>}
        </OffsetButtonWrapper>
      </ImageWrapper>
      <CardContentWrapper>
        <h1>{title}</h1>
        <Description>{description}</Description>
        <Description>2022 • italy</Description>
        <OwnerInfo address="Nazareno Biondo" />
      </CardContentWrapper>
    </CardWrapper>
  );
};

export default NFTCard;
