import React from "react";
import { Dropdown } from "components";
import { Juno, Near, Chevron } from "icons";
import {
  Container,
  Image,
  MenuContainer,
  MenuItem,
  IconWrapper,
  StyledLink,
} from "./styled";

const Navbar: React.FC = () => {
  const handleClick = () => {
    const targetDiv = window.document.getElementById("showCase");
    targetDiv?.scrollIntoView({ behavior: "smooth" });
  };
  const handleCollectionClick = () => {
    const targetDiv = window.document.getElementById("featuredCollection");
    targetDiv?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container>
      <Image src="/images/logotext.svg" />
      <MenuContainer>
        <MenuItem onClick={handleCollectionClick}>Collections</MenuItem>
        <MenuItem onClick={handleClick}>How it works</MenuItem>

        <MenuItem>
          <a
            href="https://www.marbledao.finance/The%20Marblepaper.pdf"
            target="__blank"
          >
            Marblepaper
          </a>
        </MenuItem>
      </MenuContainer>
      <Dropdown
        buttonTitle="Marketplace"
        options={[
          <StyledLink
            href="https://juno-nft.marbledao.finance"
            target="_blank"
            rel="noreferrer"
          >
            <Juno />
            <p>JUNO</p>
            <IconWrapper>
              <Chevron />
            </IconWrapper>
          </StyledLink>,
          <StyledLink
            href="https://near-nft.marbledao.finance"
            target="_blank"
            rel="noreferrer"
          >
            <Near />
            <p>NEAR</p>
            <IconWrapper>
              <Chevron />
            </IconWrapper>
          </StyledLink>,
        ]}
      />
    </Container>
  );
};

export default Navbar;
