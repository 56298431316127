import {
  IntroArea,
  FeaturedCollection,
  PowerfulFeatures,
  OriginalWork,
  Partners,
  Showcase,
  Vision,
} from "containers";

const Home: React.FC = () => {
  return (
    <>
      <IntroArea />
      <div id="featuredCollection">
        <FeaturedCollection />
      </div>
      <OriginalWork />
      <PowerfulFeatures />
      <Partners />
      <div id="showCase">
        <Showcase />
      </div>
      <Vision />
    </>
  );
};

export default Home;
