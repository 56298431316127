import React from "react";
export { default as Navbar } from "./Navbar";
export const IntroArea = React.lazy(() => import("./IntroArea"));
export const FeaturedCollection = React.lazy(
  () => import("./FeaturedCollection")
);
export const PowerfulFeatures = React.lazy(() => import("./PowerfulFeatures"));
export const OriginalWork = React.lazy(() => import("./OriginalWork"));
export const Partners = React.lazy(() => import("./Partners"));
export const Showcase = React.lazy(() => import("./Showcase"));
export const Vision = React.lazy(() => import("./Vision"));
export const Footer = React.lazy(() => import("./Footer"));
