import styled from "styled-components";

interface OwnerInfoProps {
  address: string;
}

const OwnerInfo: React.FC<OwnerInfoProps> = ({ address }) => {
  return (
    <OwnerInfoWrapper>
      <img
        src="https://framerusercontent.com/images/jloDG5lqnb043A0YybWmgVJmSs.png"
        alt="logo"
      />
      <p>Nazareno Biondo</p>
    </OwnerInfoWrapper>
  );
};

export default OwnerInfo;

const OwnerInfoWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  img {
    border-radius: 6px;
    width: 28px;
    height: 28px;
  }
  p { 
    font-size: 16px;
    color: rgba(23, 23, 23, 1);
  }
`;
