import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: EditorNote;
        src: url(/fonts/EditorNote/thin.ttf) format('truetype');
        font-weight: 100;
    }
    @font-face {
        font-family: EditorNote;
        src: url(/fonts/EditorNote/regular.ttf) format('truetype');
        font-weight: 300;
    }
    @font-face {
        font-family: EditorNote;
        src: url(/fonts/EditorNote/bold.ttf) format('truetype');
        font-weight: 700;
    }
    @font-face {
        font-family: EditorNote;
        src: url(/fonts/EditorNote/italic.ttf) format('truetype');
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: Eina03;
        src: url(/fonts/Eina03/regular.ttf) format('truetype');
        font-weight: 400;
    }
    @font-face {
        font-family: Eina03;
        src: url(/fonts/Eina03/bold.ttf) format('truetype');
        font-weight: 700;
    }
    @font-face {
        font-family: Eina03;
        src: url(/fonts/Eina03/semibold.ttf) format('truetype');
        font-weight: 500;
    }
    @font-face {
        font-family: Eina03;
        src: url(/fonts/Eina03/light.ttf) format('truetype');
        font-weight: 300;
    }

    body {
        background: url('images/background.png');
        margin: 0;
        ::-webkit-scrollbar-thumb {
        background: rgb(129, 142, 163);
        border-radius: 8px;
        }
        ::-webkit-scrollbar {
            width: 0px;
        }
        * {
        box-sizing: border-box;
        margin: 0;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
    }
`;

export const GlobalContainer: React.FC<{
  children: React.ReactNode;
  background?: string;
}> = ({ children, background = "transparent" }) => {
  return (
    <Wrapper background={background}>
      <FixedWidthWrapper>{children}</FixedWidthWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ background: string }>`
  width: 100%;
  background-color: ${({ background }) => background} !important;
`;

const FixedWidthWrapper = styled.div<{ background?: string }>`
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 0;
  position: relative;
`;

export default GlobalStyle;
